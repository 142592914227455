const cityList = {
  1: "南宁",
  2: "柳州",
  3: "桂林",
  4: "梧州",
  5: "北海",
  6: "防城港",
  7: "钦州",
  8: "贵港",
  9: "玉林",
  10: "百色",
  11: "贺州",
  12: "河池",
  13: "来宾",
  14: "崇左",
};
export { cityList };
