import home from "./../views/home.vue";
import school from "./../views/citySchools.vue";
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: home,
    },
    {
      path: "/school/:cityIndex",
      name: "school",
      component: school,
    },
  ],
});

export default router;
