/*
 * @Author: hxz
 * @Date: 2022-12-05 11:52:04
 * @LastEditors: hxz
 * @LastEditTime: 2023-01-09 09:27:27
 * @Description:
 */
// 服务器文件夹路径,vue.config.js ==> publicPath
let base = process.env.BASE_URL;
const baseApi = "/api";
// 请求
const httpBase = {
  development: "https://jxglxttest.jinlingkeji.cn",
  test: "https://jxglxttest.jinlingkeji.cn",
  production: "https://zhxygl.jinlingkeji.cn",
};
// 部署域名
const domainBase = {
  development: "https://jxglxttest.jinlingkeji.cn" + base,
  test: "https://jxglxttest.jinlingkeji.cn" + base,
  production: "https://jxgl.jinlingkeji.cn" + base,
};

const httpBaseUrl = httpBase[process.env.NODE_ENV];
const domainBaseUrl = domainBase[process.env.NODE_ENV];

export { httpBaseUrl, baseApi, domainBaseUrl };
