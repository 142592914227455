import axios from "axios";
import { httpBaseUrl } from "./base.js";
// 创建axios实例，在这里可以设置请求的默认配置
const instance = axios.create({
  timeout: 10000,
  baseURL: httpBaseUrl,
});
// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
instance.defaults.headers.post["Content-Type"] = "application/json";
/** 添加请求拦截器 **/
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

/** 添加响应拦截器  **/
instance.interceptors.response.use(
  (response) => {
    console.log(response);
    if (response.status === 200) {
      // 响应结果里的status: ok是我与后台的约定，大家可以根据实际情况去做对应的判断
      if (response.data.code === 1) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(response.data.msg);
    }
  },
  (error) => {
    console.log(error)
    if (error.response) {
      return Promise.reject(error);
    } else {
      return Promise.reject(new Error("请求超时, 请刷新重试sfsdfdsfs"));
    }
  }
);
/* 统一封装get请求 */
export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "get",
      url,
      params,
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* 统一封装post请求  */
export const post = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "post",
      url,
      data,
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
