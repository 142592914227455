<template>
  <div class="home">
    <img src="./../assets/image/home/title.png" alt="" class="title" />
    <div class="citys">
      <img
        src="./../assets/image/home/school.png"
        alt=""
        class="school"
        @click="goSchool"
      />
      <div class="city-part">
        <img
          src="./../assets/image/home/city_1.png"
          alt=""
          class="city"
          @click="goCitySchool(1)"
        />
        <img
          src="./../assets/image/home/city_2.png"
          alt=""
          class="city"
          @click="goCitySchool(2)"
        />
        <img
          src="./../assets/image/home/city_3.png"
          alt=""
          class="city"
          @click="goCitySchool(3)"
        />
        <img
          src="./../assets/image/home/city_4.png"
          alt=""
          class="city"
          @click="goCitySchool(4)"
        />
        <img
          src="./../assets/image/home/city_5.png"
          alt=""
          class="city"
          @click="goCitySchool(5)"
        />
        <img
          src="./../assets/image/home/city_6.png"
          alt=""
          class="city"
          @click="goCitySchool(6)"
        />
        <img
          src="./../assets/image/home/city_7.png"
          alt=""
          class="city"
          @click="goCitySchool(7)"
        />
        <img
          src="./../assets/image/home/city_8.png"
          alt=""
          class="city"
          @click="goCitySchool(8)"
        />
        <img
          src="./../assets/image/home/city_9.png"
          alt=""
          class="city"
          @click="goCitySchool(9)"
        />
        <img
          src="./../assets/image/home/city_10.png"
          alt=""
          class="city"
          @click="goCitySchool(10)"
        />
        <img
          src="./../assets/image/home/city_11.png"
          alt=""
          class="city"
          @click="goCitySchool(11)"
        />
        <img
          src="./../assets/image/home/city_12.png"
          alt=""
          class="city"
          @click="goCitySchool(12)"
        />
        <img
          src="./../assets/image/home/city_13.png"
          alt=""
          class="city"
          @click="goCitySchool(13)"
        />
        <img
          src="./../assets/image/home/city_14.png"
          alt=""
          class="city"
          @click="goCitySchool(14)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { httpBaseUrl } from "./../util/base.js";
import { getSchoolId } from "./../api/home.js";
export default {
  data() {
    return {
      gxId: "",
    };
  },
  created() {
    this.getSchoolId("广西老年大学");
  },
  methods: {
    async getSchoolId(name) {
      let res = await getSchoolId(name);
      this.gxId = res.data.data.id;
    },
    goSchool() {
      //直接去广西老年大学登录页面
      // let id;
      // process.env.NODE_ENV == "production" ? (id = 35) : (id = 10);
      if (!this.gxId) return;
      window.location.href =
        httpBaseUrl +
        `/admin_aujqj849ajhanxg62a4bz5ko8ds9qk2ljs6ha.php/index/entry/id/${this.gxId}`;
    },
    goCitySchool(index) {
      this.$router.push(`/school/${index}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 800px) {
  .city-part {
    grid-gap: 28px 10px;
  }
}
@media (min-width: 1280px) {
  .city-part {
    grid-gap: 28px 30px;
  }
}
@media (min-width: 1440px) {
  .city-part {
    grid-gap: 28px 50px;
  }
}
.home {
  padding: 0 30px;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
  .title {
    width: 881px;
    height: 176px;
    margin-top: 150px;
    margin-bottom: 100px;
  }
  .citys {
    display: flex;
    justify-content: center;
    .school {
      width: 142px;
      height: 272px;
      margin-right: 32px;
      &:hover {
        background-color: #fff;
        border-radius: 14px;
      }
    }
    .city-part {
      width: 1294px;
      display: grid;
      justify-content: space-evenly;
      grid-template-columns: repeat(auto-fill, 142px);
      grid-gap: 28px;
      .city {
        position: relative;
        width: 142px;
        height: 122px;
        &:hover {
          background-color: #fff;
          border-radius: 14px;
        }
      }
    }
  }
}
</style>
