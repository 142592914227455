<template>
  <div class="city-list">
    <img src="./../assets/image/home/title.png" alt="" class="title" />
    <div class="city-name">({{ cityName }}市)</div>
    <div class="schools">
      <div
        v-for="(item, index) in schoolList"
        :key="index"
        class="school"
        @click="goLogin(item.id)"
      >
        <img :src="item.image" alt="" class="avatar" v-if="item.image" />
        <div class="avatar blank-avatar" v-else>
          <img src="./../assets/image/home/blank.png" alt="" />
          <div class="blank-name">{{ item.name.substr(0, 2) }}</div>
        </div>
        <div class="name common-line-clamp-2">
          {{ item.name.substr(0, item.name.length - 4) }}
          <span class="tip">{{ item.name.substr(-4) }}</span>
        </div>
      </div>
      <img
        src="./../assets/image/home/back.png"
        alt=""
        class="back"
        @click="goBack"
      />
    </div>
  </div>
</template>

<script>
import { cityList } from "./../util/publicData.js";
import { getSchoolList } from "./../api/home.js";
import { httpBaseUrl } from "./../util/base.js";
export default {
  data() {
    return { cityName: "", schoolList: [] };
  },
  created() {
    let cityIndex = this.$route.params.cityIndex;
    this.cityName = cityList[cityIndex];
    this.getSchool(this.cityName);
  },
  methods: {
    async getSchool(name) {
      let res = await getSchoolList(name);
      this.schoolList = res.data.data;
    },
    goLogin(id) {
      window.location.href =
        httpBaseUrl +
        `/admin_aujqj849ajhanxg62a4bz5ko8ds9qk2ljs6ha.php/index/entry/id/${id}`;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
%width {
  width: 142px;
  height: 144px;
}
.city-list {
  padding: 0 30px;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
  .title {
    width: 881px;
    height: 176px;
    margin-top: 150px;
    margin-bottom: 10px;
  }
  .city-name {
    font-weight: bold;
    font-size: 50px;
    color: #333333;
    margin-bottom: 20px;
  }
  .schools {
    margin: 0 100px;
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, 142px);
    grid-gap: 28px;
    .school {
      @extend %width;
      padding: 12px 16px 0 16px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 14px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .avatar {
        width: 70px;
        height: 70px;
        margin-bottom: 6px;
        border-radius: 50%;
      }
      .blank-avatar {
        position: relative;
        overflow: hidden;
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 70px;
          height: 70px;
          object-fit: cover;
        }
        .blank-name {
          font-weight: Bold;
          font-size: 22px;
          color: #f2323a;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          min-width: 44px;
          line-height: 22px;
        }
      }
      .name {
        font-size: 16px;
        color: #333333;
        width: 100px;
        .tip {
          display: inline-block;
        }
      }
      &:hover {
        background-color: #fff;
        border-radius: 14px;
      }
    }
    .back {
      @extend %width;
      &:hover {
        background-color: #fff;
        border-radius: 14px;
      }
    }
  }
}
</style>
